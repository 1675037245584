import{ useState, useEffect } from "react";
import { collection, doc, addDoc, setDoc, CollectionReference , getDocs} from "firebase/firestore";
import { auth, db, analytics,functions } from "firebaseconfig.js"
import { signInWithEmailAndPassword,sendEmailVerification,sendPasswordResetEmail,deleteUser, onAuthStateChanged, createUserWithEmailAndPassword, AuthErrorCodes, updateProfile, updatePhoneNumber, setPersistence, browserSessionPersistence, signOut } from 'firebase/auth';
// import { AppointmentsSync } from "./calendar.js";
import { httpsCallable } from "firebase/functions";
import { ToastContainer, toast } from 'react-toastify';
//import { event } from "firebase-functions/v1/analytics";
//import { uploadcalendarappointments } from "../functions/index.js";

 export const uploadCalendarAppointments = httpsCallable(functions, 'uploadCalendarAppointments',{limitedUseAppCheckTokens:true});



export const signIn = async (loginEmail, loginPassword) => {
 
    await signInWithEmailAndPassword(auth, loginEmail, loginPassword)
    .then((userCredential) => {
      // Signed in 
      try {
        const userID =userCredential.uid;
      } catch (error) {
        console.error('Error:', error.message);
      }
     
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      if(error.message==="Firebase: Error (auth/wrong-password)."){
        toast("Incorrect Password")
      }
      else{ toast(errorMessage)}
      
     
    });
  // catch (error) {
  //  console.log(`There was an error: ${error}`)
  //   showLoginError(error)
  // }
}
export const resetPassword = async (email, ) => {
sendPasswordResetEmail (auth, email)
  .then(() => {
    // Password reset email sent!
    // ..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ..
  })
}
// const showLoginError = (error) => {
//   if (error.code === AuthErrorCodes.INVALID_PASSWORD) {
//     toast('Incorrect Password');
//   } else {
//     toast(`Error: ${error.message}`);
//   }
// };

const showLoginError = (error) => {  
  if (error.code == AuthErrorCodes.INVALID_PASSWORD) {
    alert('wrong password');
    
  }
  else {
    alert(`Error: ${error.message}`     ) 
  }
}

export const createAccount = async (email, password, name, phoneNumber) => {
  
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
     updateProfile(auth.currentUser, {
      displayName: name,
      photoURL: "https://example.com/jane-q-user/profile.jpg"
    });
    const userID = userCredential.user.uid;
    
    const UserDoc = doc(collection(db, "users"), userID);
    const userData = {
      email: email,
      displayName: name,
      phonenumber: phoneNumber
    };
    
   setDoc(UserDoc, userData);
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
   // console.log(`There was an error: ${error}`)
    //toast(errorMessage)
    showLoginError(error)
  }) 
};

export const logout = async () => {
  await signOut(auth);
}

// export const getAppointments = async ()=>{
//   try {
//     const userID = auth.user.uid;
//     const userAppointmentsRef = doc(db, "users", auth.currentUser.uid, "appts");
    
//     uploadCalendarAppointments(auth)
//     .then((events)=>{
//       for(const event in events){
//         addDoc(userAppointmentsRef,event)
//       }
//     });
    
   
//     //console.log(result.data);
//   } catch (error) {
//     console.error('Error:', error.message);
//   }
// };

export const AppointmentsTable = () => {
  const [appointments, setAppointments] = useState([]);
  const fetchAppointments = async () => {
    try {
      await uploadCalendarAppointments(functions.app);
      const userAppointmentsRef = collection(db, "users", auth.currentUser.uid, "appointments");
      const querySnapshot = await getDocs(userAppointmentsRef);

      const appointmentsData = [];
      querySnapshot.forEach((doc) => {
        appointmentsData.push(doc.data());
      });

      setAppointments(appointmentsData);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  return (
    <div className="appointments-container">
    <h2>Appointments</h2>
    {appointments.length === 0 ? (
      <p className="no-appointments-message">No documents available</p>
    ) : (
      <table className="appointments-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Start Time</th>
            <th>End Time</th>
          </tr>
        </thead>
        <tbody>
          {appointments.map((appointment) => (
            <tr key={appointment.id}>
              <td>{appointment.summary}</td>
              <td>{appointment.start.dateTime}</td>
              <td>{appointment.end.dateTime}</td>
            </tr>
          ))}
        </tbody>
      </table>
    )}
  </div>
  );
};

// export const loadAppointmetns = async () =>{
//   const docRef = doc(db, "users", auth.user.uid)
//   const docSnap = await getDoc(docRef);
//   let userAppoinmetn
// if (docSnap.exists()) {
//   console.log("Document data:", docSnap.data());
// } else {
//   // docSnap.data() will be undefined in this case
//   console.log("No such document!");
// }
// }

// export const createAccount = async (email, password, name, phoneNumber) => {
//   try {
//     const userCredential = await createUserWithEmailAndPassword(auth, email, password);
//     await updateProfile(auth.currentUser, {
//       displayName: name,
//       photoURL: "https://example.com/jane-q-user/profile.jpg"
//     });
//     const userID = userCredential.user.uid;
    
//     const UserDoc = doc(collection(db, "users"), userID);
//     const userData = {
//       userID: userCredential.user.uid,
//       email: email,
//       displayName: name,
//       phonenumber: phoneNumber
//     };
    
//    setDoc(UserDoc, userData);
//   }catch(error) {
//     console.log(`There was an error: ${error}`)
//     showLoginError(error)
//   } 
// };



  // export const monitorAuthState = async () => {
  //   onAuthStateChanged(auth, user => {
  //     if (user) {
  //       return true;
  //     }
  //     else {
  //       return false;
  //     }
  //   })
  // }


  // const addUserProfile = async () =>{
//   const user = auth.currentUser;
//   if (!user) {
//     console.log("User not authenticated");
//     return;
//   }

//   try {

//     const docRef = await addDoc(collection(firestore, "users"), {
//      userName:auth.currentUser.displayName,
//      email: auth.currentUser.email,
//      userID:auth.currentUser.providerId
//     });


//     // firestore.collection("users").add({
//     //   userID:user.uid,
//     // })
//   //   const userDoc = doc(firestore,'users',user.uid)
//   //  const docRef = await setDoc(userDoc,{merge:true} )
//     // const docRef = await addDoc(collection(firestore, "users"), {
//     //   name: user.displayName,
//     //   user: user.uid
//     // });
//     // console.log("Document written with ID:", docRef.id);
//   } catch (error) {
//     console.error("Error writing document:", error);
//   }
// };
